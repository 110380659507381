<template>
    <div>
        <b-modal
        id="modal-copy-revenue-center"
        v-model="isOpen"
        size="lg"
        centered
        hide-header
        cancel-variant="none"
        ok-variant="primary"
        :no-close-on-backdrop="true"
        >
        <b-card-header class="banned-background-color-primary">
                <div class="d-flex justify-content-between align-items-center block-header">
                <h3  class="mb-0 title-modal color-primary">
                    Thêm nhóm doanh thu
                </h3>
                <feather-icon
                    icon="XIcon"
                    size="24"
                    class="cursor-pointer color-primary"
                    @click="hideModal"
                />
                </div>
            </b-card-header>
        <b-overlay 
            :opacity="0.3"  
            rounded="sm"
        >
        <b-card-body>
            <b-row>
                    <b-col cols="12">
                      <b-form-group >
                        <label class="mb-1 d-flex">
                          <span style="margin:auto 10px auto 0">Chọn nhóm doanh thu</span>
                        </label>
                        <Treeselect
                          v-model="ListRevenueSelect"
                          :options="listRevenue"
                          :clearable="false"
                          class="d-tree-select"
                          placeholder="Chọn nhóm doanh thu"
                          :multiple="true"
                          :clear-on-select="false"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
            </b-card-body>
            
        </b-overlay>
            
        <template #modal-footer>
      
      <b-button variant="primary" @click="copyRevenueCenter()">
          Lưu
      </b-button>
      
      <b-button  variant="secondary" @click="hideModal()">
          Quay lại
      </b-button>
  </template>
            
        </b-modal>
    </div>
</template>
<script>
import DxTreeView from 'devextreme-vue/tree-view'
import DxList from 'devextreme-vue/list'
import Treeselect from '@riophae/vue-treeselect'
import DxSelectBox from 'devextreme-vue/select-box'
import DxCheckBox from 'devextreme-vue/check-box'
import 'devextreme/dist/css/dx.light.css'
import { reportSetting } from '@/api/Report-Setting'
export default {
  components: {
    DxTreeView,
    DxList,
    DxSelectBox,
    DxCheckBox,
    Treeselect
  },
  props: [
    'listRevenue',
    'RevenueSelect'
  ],
    data() {
        return {
            isOpen: false,
            ListRevenueSelect: [],
        }
    },
    computed: {
    treeView() {
      return this.$refs[treeViewRef].instance
    },
  },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-copy-revenue-center')
        },
        async copyRevenueCenter() {
          if(this.ListRevenueSelect.length > 0) {
            const body = {
                RevenueCenterId: this.RevenueSelect,
                ListRevenueCenterIdCopy: this.ListRevenueSelect,
                ListRevenueCenterCodeCopy: [],
            }
            const response = await reportSetting.api_URCZ02(body)
            this.showResToast(response)
            if(response.Status == 200) {
              this.ListRevenueSelect = []
              this.hideModal()
              this.$emit('event')
            }
          } else {
            this.showToast('error', `Vui lòng chọn ít nhất 1 nhóm doanh thu`) 
          }
        }
    },
}
</script>
<style lang="scss">
    
</style>