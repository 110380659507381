import DxTreeView from 'devextreme-vue/tree-view'
import DxList from 'devextreme-vue/list'
import Treeselect from '@riophae/vue-treeselect'
import DxSelectBox from 'devextreme-vue/select-box'
import DxCheckBox from 'devextreme-vue/check-box'
import 'devextreme/dist/css/dx.light.css'
import { config } from '@/api/config-setting'
import { golfClassSetting } from '@/api/golf-class-setting'
import { commonServices } from '@/api/common-services'
import { caddySetting } from '@/api/caddy-setting'
import { members } from '@/api/members'
import { courseSetting } from '@/api/course-setting'
import { reportSetting } from '@/api/Report-Setting'
import modalAddRevenueCenterVue from './modalAddRevenueCenter.vue'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { rateSetting } from '@/api/rate-setting'
import { booking } from '@/api/booking'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import modalCopyRevenue from './modalCopyRevenue.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  required, min, max,
} from '@validations'
const treeViewRef = 'treeView'
export default {
  name: 'GroupServiceSetting',
  components: {
    DxTreeView,
    DxList,
    DxSelectBox,
    DxCheckBox,
    modalAddRevenueCenterVue,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    Treeselect,
    modalCopyRevenue,
    AppTimeline,
    AppTimelineItem
  },
  data() {
    const selectionModes = ['multiple', 'single']
    const showCheckBoxesModes = ['normal', 'selectAll', 'none']
    return {
      selectedService: [],
      showCheckBoxesModes,
      selectionModes,
      showCheckBoxesModeValue: 'normal',
      selectionModeValue: selectionModes[0],
      isSelectionModeDisabled: false,
      isRecursiveDisabled: false,
      selectNodesRecursiveValue: true,
      selectByClickValue: true,
      treeViewRef,
      listRevenueCenter: null,
      listRevenueCenterProps: [],
      RevenueCenterSelected: null,
      ListBarLocaltion: [],
      BarLocaltionSelectService: [],
      BarLocaltionSelectPayment: [],
      dataProps: {},
      isDetailParent: true,
      isDetailChild: true,
      ListService: null,
      listBarLocaltionByService: null,
      listBarLocaltionByPaymentMethod: null,
      ListRevenueCenterZoneService: null,
      ListRevenueCenterZonePayment: null,
      dataRevenueCenter: null,
      inputMaskTime: {
        time: true,
        timePattern: ['h', 'm'],
      },
      RevenueCenterType: [
        {
            label: 'Thanh toán',
            value: 'PAY'
        },
        {
            label: 'Doanh thu',
            value: 'REV'
        },
        {
            label: 'Thống kê',
            value: 'ST'
        },
        {
            label: 'Doanh thu Khách sạn',
            value: 'REV_HOTEL'
        },
        {
            label: 'Thanh toán Khách sạn',
            value: 'PAY_HOTEL'
        }
      ],
      TaxsType: [
          {
              label:'Không bao gồm thuế phí',
              value:'NET'
          },
          {
              label:'Đã bao gồm thuế phí',
              value:'TOTAL'
          },
          {
              label:'Thuế VAT',
              value:'VAT_TAX'
          },
          {
              label:'Thuế tiêu thụ đặc biệt',
              value:'S_TAX'
          },
          {
              label:'Phí dịch vụ',
              value:'SVC'
          }
      ],
      isLoading: false,
      itemsPaymentMethod: null,
      dataProcessing: {
        StartDate: this.getUTC('start', 'day'),
        EndDate:this.getUTC('start', 'day'),
        RevenueCenterId: null
      },
      courses: {
        list: null,
        selected: []
      },
      golfClasses: {
        list: null,
        selected: []
      },
      ListTimeShift: null,
      optionsSTType: [
        {
          label:'Số round',
          value:'ROUND'
        },
        {
            label:'Số hố',
            value:'HOLE'
        },
        {
            label:'Số golfer',
            value:'GOLFER'
        },
        {
            label:'Số lần booking',
            value:'TIME_BOOKING'
        },
        {
            label:'Số lần hủy',
            value:'TIME_CANCEL'
        },
        {
            label:'Số lần sử dụng dịch vụ',
            value:'TIME_USING_SERVICE'
        }
      ],
      listHotelTransactionCode: null,
      ListHotel: {
        list: null,
        selected: []
      },
      itemTransactionCode: [
        
      ],
      selectedTransactionCode: null,
      optionsOperator: [
        {
          label: 'IN',
          value: 'IN'
        }
        ,
        {
          label: 'NOT IN',
          value: 'NOT_IN'
        }
      ],
      optionBookingStatus: [
          {
              value:'BOOKED',
              label:'Booked'
          },
          {
              value:'CHECK_IN',
              label:'Check in'
          },
          {
              value:'CHECK_OUT',
              label:'Check out'
          },
          {
              value:'CANCELLED',
              label:'Hủy'
          },
          {
              value:'NO_SHOW',
              label:'No show'
          }
      ],
      RevenueCenterCourseOperator: null,
      RevenueCenterNationalityCodeOperator: null,
      RevenueCenterSexCodeOperator: null,
      RevenueCenterGolfClassOperator: null,
      RevenueCenterHotelTransactionCodeOperator: null,
      RevenueCenterTimeOperator: null,
      RevenueCenterZoneOfPaymentOperator: null,
      RevenueCenterZoneOfServiceOperator: null,
      ListBookingDetailStatus: null,
      isLoadingDataProcess: false,
      min: null,
      max: null,
      check: null,
      minTotal: null,
      maxTotal: null,
      checkTotal: null,
      listSex: {
        list: null,
        selected: []
      },
      currentLang: this.$i18n.locale,
      countries: {
        list: null,
        selected: []
      },
      listSexIcon: [
        {
          value: 'SEX_M',
          icon: 'ezGolf-icon-male',
        },
        {
          value: 'SEX_F',
          icon: 'ezGolf-icon-female',
        },
        {
          value: 'SEX_LGBT',
          icon: 'ezGolf-icon-other-gender',
        },
      ],
      isCheckAllNation: false,
      ListDiscount: [],
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 0,
      },
      listRate: null,
      listRateSelect: null,
      RevenueCenterRateOperator: null,
      ListAllServiceNoRevenueCenter: [],
      ListServiceNoSelect : null,
      ListPBI: null,
      ListPBISelect: null,
      listRevenueConfigDuplicate: null,
      listServiceDuplicate: [],
      listHistoryRevenueCenter: null,
      listRevenueCenterCheckDuplicate: null
    }
  },
  computed: {
    treeView() {
      return this.$refs[treeViewRef].instance
    },
  },
  async created() {
    this.getListBarLocaltion()
    this.getListRevenueCenter()
    this.getListHotelz()
    this.getListSex()
    this.getListCodePBI()
    this.getCountries_RNA01()
    this.getListRateCode_RRA01_AC2()
    this.getListServiceNoRevenueCenter()
    await this.getListCourse()
    await this.getGolfClass_RGC01()
  },
  watch: {
    // listBarLocaltionByService(value) {
    //   if(value.length > 0) {
    //     this.selectBarLocaltion(value)
    //   } else {
    //     this.ListService = null
    //   }
    // }
  },
  methods: {
    // lấy danh sách payment
    async getPaymentMethod_RPF02(data) {
      const body = {
        ZoneId: data.map(x => String(x.Id))
      }
      await booking.api_RPF02(body).then(async response => {
        if (response.Status === '200') {
          var data = JSON.parse(JSON.stringify(this.listBarLocaltionByPaymentMethod))
          data.forEach(x => {
            x.text = x.Code + ' - ' + x.Name
            x.items = JSON.parse(JSON.stringify(response.Data.PaymentType))
            x.items.forEach(y => {
              y.text = y.Code + ' - ' + y.Name
              y.items = y.PaymentMethod
              y.items.forEach(z => {
                z.text = z.Code + ' - ' + z.Name
                z.ZoneId = x.Id
                z.PaymentTypeCode = y.Code
                this.ListRevenueCenterZonePayment.forEach(k => {
                  if(k.ZoneId === x.Id) {
                    k.RevenueCenterZonePaymentMethod.forEach(m => {
                      if(m.PaymentTypeCode === y.Code && m.PaymentMethodId === z.Id) {
                        z.selected = true
                      }
                    })
                  }
                })
              })
            })
          })
          this.itemsPaymentMethod = data
          this.isLoading = false
        }
      })
    },
    // lấy danh sách TransactionCode
    async getListTransactionCode(data) {
      await commonServices.getListTransactionCode()
      .then(res => {
        var dataTemp = []
        dataTemp.push(
          {
            TransactionCode: "TransactionCode",
            Description: "Description",
            type: 'All',
            items: []
          }
        )
        var listTransactionCode1 = []
        res.Data.TransactionCode.forEach(x => {
          x.text = x.TransactionCode + ' - ' + x.Description
          if(data.find(dt => dt.TransactionCode === x.TransactionCode1 && dt.TransactionSubCode === x.TransactionSubCode)) {
            x.selected = true
          } else {
            x.selected = false
          }
          if(!listTransactionCode1.find(y => y === x.TransactionCode1)) {
            listTransactionCode1.push(x.TransactionCode1)
          }
        })
        listTransactionCode1.forEach(z => {
          dataTemp[0].items.push(
            {
              TransactionCode: z,
              Description: "Description",
              type: 'menu',
              items: res.Data.TransactionCode.filter(trans => trans.TransactionCode1 === z)
            }
          )
        })
        var listHotelTemp = []
        data.forEach(hotel => {
          if(!listHotelTemp.find(HotelTemp => HotelTemp === hotel.HotelId)) {
            listHotelTemp.push(hotel.HotelId)
          }
        })
        this.ListHotel.selected = listHotelTemp
        this.itemTransactionCode = dataTemp
      })
    },
    // lấy danh sách khách sạn
    async getListHotelz() {
      await courseSetting.getListHotel()
      .then(res => {
        this.ListHotel.list = res.Data.Hotel
      })
    },
    // lấy danh sách giới tính
    async getListSex() {
      await commonServices
      .getCommon({ KeyGroup: 'SEX' })
      .then(res => {
        if (res) {
          this.listSex.list = res.Data.filter(x => x.LangId == this.currentLang)
        }
      })
      // await commonServices
      // .getCommon({ KeyGroup: 'BOOKING_CONFIG' })
      // .then(res => {
      //   if (res) {
      //     this.listRevenueConfigDuplicate = res?.Data.find(x => x.KeyCode === 'LIST_REVENUE_CONFIG').KeyValue
      //     this.getListServiceDuplicate()
      //   }
      // })
    },
    async getListCodePBI() {
      await commonServices
      .getCommon({ KeyGroup: 'MODULE' })
      .then(res => {
        if(res.Status == 200) {
          this.ListPBI = res.Data.find(x => x.KeyCode == 'POWER_BI_REV_CODE') ? JSON.parse(res.Data.find(x => x.KeyCode == 'POWER_BI_REV_CODE').KeyValue) : null
        }
      })
    },
    // lấy danh sách quốc tịch
    async getCountries_RNA01() {
      const response = await members.getCountries({})
      if (response) {
        this.countries.list = response.Data.Country
      }
    },
    // lấy danh sahchs sân
    async getListCourse() {
      await courseSetting.getListCourse().then(res => {
        this.courses.list = res?.Data?.Course.map(x => Object.assign(x, { holeAmount: x.NumberOfHoles }))
      })
    },
    // lấy danh sách golf class
    async getGolfClass_RGC01() {
      var listCourseId = []
      this.courses.list.forEach(x => {
        listCourseId.push(x.Id)
      })
      const response = await golfClassSetting.getListGolfClass({ CourseId: listCourseId})
      if (response.Status === '200') {
        this.golfClasses.list = response?.Data?.GolfClass
      }
    },
    // lấy danh sách khu vực doanh thu
    async getListRevenueCenter() {
      await reportSetting.api_RRCE01().then(res => {
        this.listRevenueCenterProps = res.Data.RevenueCenter.filter(x => x.ParentId)
        this.listRevenueCenterProps.forEach(y => {
          y.id = y.Id
          y.label = y.RevenueCenterName
        })
        var listRevenueCenterTemp = []
        res.Data.RevenueCenter.forEach(x => {
          x.id = x.Id
          x.label = x.RevenueCenterName
          if(!x.ParentId) {
            listRevenueCenterTemp.push(
              {
                id: x.Id,
                label: x.RevenueCenterName,
                Id:x.Id,
                ParentId:x.ParentId,
                RevenueCenterName:x.RevenueCenterName,
                RevenueCenterCode:x.RevenueCenterCode,
                Note:x.Note,
                RevenueCenterChild: res.Data.RevenueCenter.filter(y => y.ParentId === x.Id),
                children: res.Data.RevenueCenter.filter(y => y.ParentId === x.Id)
              }
            )
          }
        })
        this.listRevenueCenter = listRevenueCenterTemp
      })
    },
    // chọn 1 khu vực kinh doanh => chi tiết khu vực doanh thu
    async SelectRevenueCenter(value) {
      this.isLoading = true
      if(typeof(value) == 'number') {
        this.detailRevenueCenter(value)
      } else {
        this.RevenueCenterSelected = value.Id
        this.dataRevenueCenter =  JSON.parse(JSON.stringify(value))
        if(value.ParentId) {
          this.detailRevenueCenter(value.Id)
        } else {
          this.isLoading = false
        }
      }
      
    },
    // chi tiết khu vực doanh thu 
    async detailRevenueCenter(Id) {
      const body = {
        RevenueCenterId: Id
      }
      reportSetting.api_RRCE05(body).then(res => {
        this.listHistoryRevenueCenter = res.Data.TransactionRevenue
      })
      this.getListServiceNoRevenueCenter()
      // this.getListServiceDuplicate()
      await reportSetting.api_RRCZ01(body).then(res => {
            var listZoneSelectServiceTemp = []
            var listZoneSelectPaymentTemp = []
            this.courses.selected = res.Data.RevenueCenterCourse.map(x => x.CourseId)
            this.listSex.selected = res.Data.SexCode
            this.ListDiscount = res.Data.RevenueCenterDiscount
            this.countries.selected = res.Data.NationalityCode
            this.golfClasses.selected = res.Data.RevenueCenterGolfClass.map(x => x.GolfClassId)
            this.ListTimeShift = res.Data.RevenueCenterTime
            this.ListBookingDetailStatus = res.Data.BookingDetailStatus
            if(res.Data.RevenueCenterCourseOperator) {
              this.RevenueCenterCourseOperator = res.Data.RevenueCenterCourseOperator
            } else {
              this.RevenueCenterCourseOperator = 'IN'
            }
            if(res.Data.RevenueCenterNationalityCodeOperator) {
              this.RevenueCenterNationalityCodeOperator = res.Data.RevenueCenterNationalityCodeOperator
            } else {
              this.RevenueCenterNationalityCodeOperator = 'IN'
            }
            if(res.Data.RevenueCenterSexCodeOperator) {
              this.RevenueCenterSexCodeOperator = res.Data.RevenueCenterSexCodeOperator
            } else {
              this.RevenueCenterSexCodeOperator = 'IN'
            }
            if(res.Data.RevenueCenterGolfClassOperator) {
              this.RevenueCenterGolfClassOperator = res.Data.RevenueCenterGolfClassOperator
            } else {
              this.RevenueCenterGolfClassOperator = 'IN'
            }
            if(res.Data.RevenueCenterHotelTransactionCodeOperator) {
              this.RevenueCenterHotelTransactionCodeOperator = res.Data.RevenueCenterHotelTransactionCodeOperator
            } else {
              this.RevenueCenterHotelTransactionCodeOperator = 'IN'
            }
            if(res.Data.RevenueCenterTimeOperator) {
              this.RevenueCenterTimeOperator = res.Data.RevenueCenterTimeOperator
            } else {
              this.RevenueCenterTimeOperator = 'IN'
            }
            if(res.Data.RevenueCenterZoneOfPaymentOperator) {
              this.RevenueCenterZoneOfPaymentOperator = res.Data.RevenueCenterZoneOfPaymentOperator
            } else {
              this.RevenueCenterZoneOfPaymentOperator = 'IN'
            }
            if(res.Data.RevenueCenterZoneOfServiceOperator) {
              this.RevenueCenterZoneOfServiceOperator = res.Data.RevenueCenterZoneOfServiceOperator
            } else {
              this.RevenueCenterZoneOfServiceOperator = 'IN'
            }
            if(res.Data.RevenueCenterRate.length > 0) {
              this.RevenueCenterRateOperator = res.Data.RevenueCenterRate[0].Operator
            } else {
              this.RevenueCenterRateOperator = 'IN'
            }
            this.listRateSelect = res.Data.RevenueCenterRate.map(x => x.RateId)
            this.ListRevenueCenterZoneService = res.Data.RevenueCenterZoneOfService
            this.ListRevenueCenterZonePayment = res.Data.RevenueCenterZoneOfPayment
            this.getListTransactionCode(res.Data.RevenueCenterHotelTransactionCode)
            if(res.Data.RevenueCenterZoneOfService.length > 0) {
              res.Data.RevenueCenterZoneOfService.forEach(x => {
                this.ListBarLocaltion.find(y => {
                  if(y.Id === x.ZoneId) {
                    listZoneSelectServiceTemp.push(y)
                  }
                })
              })
            }
            if(res.Data.RevenueCenterZoneOfPayment.length > 0) {
              res.Data.RevenueCenterZoneOfPayment.forEach(x => {
                this.ListBarLocaltion.find(y => {
                  if(y.Id === x.ZoneId) {
                    listZoneSelectPaymentTemp.push(y)
                  }
                })
              })
            }
            this.BarLocaltionSelectService =  JSON.parse(JSON.stringify(listZoneSelectServiceTemp))
            this.BarLocaltionSelectPayment =   JSON.parse(JSON.stringify(listZoneSelectPaymentTemp))
            this.listBarLocaltionByService = JSON.parse(JSON.stringify(listZoneSelectServiceTemp))
            this.listBarLocaltionByPaymentMethod = JSON.parse(JSON.stringify(listZoneSelectPaymentTemp))
            if(this.listBarLocaltionByService.length > 0) {
              this.selectBarLocaltion(this.listBarLocaltionByService)
            } else {
              this.ListService = null
              this.isLoading = false
            }
            if(this.listBarLocaltionByPaymentMethod.length > 0) {
              this.getPaymentMethod_RPF02(this.listBarLocaltionByPaymentMethod)
            } else {
              this.itemsPaymentMethod = null
              this.isLoading = false
            }
          })
    },
    // lấy danh sách khu vực kinh doanh
    async getListBarLocaltion() {
      await config.API_RBAAR().then(res => {
        this.ListBarLocaltion = res.Data.Bar
      })
    },
    // lấy dịch vụ từ khu vực kinh doanh đã chọn
    async selectBarLocaltion(data) {
      var ZoneIdTemp = []
      data.forEach(x => {
        ZoneIdTemp.push(String(x.Id))
      })
      const body = {
        HotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
        ZoneId: ZoneIdTemp,
        IsActiveOfMenu: null
      }
      await booking.getListServicesFee(body).then(res => {
        res.Data.Menu.forEach(x => {
          x.items = x.MenuDetail
          x.Code = x.MenuCode
          x.ProductName = x.MenuName
          x.text = x.Code + ' - ' + x.ProductName
          x.items.forEach(y => {
            y.ZoneId = x.ParentMenuId
            y.text = y.TransactionCode.TransactionCode + ' - ' + y.Code + ' - ' + y.ProductName
            this.ListRevenueCenterZoneService.forEach(z => {
              if(z.RevenueCenterZoneService.length > 0) {
                if(z.RevenueCenterZoneService.find(k => k.ServiceId === y.ProductId) && y.ZoneId == z.ZoneId) {
                  y.selected = true 
                }
              }
            })
          })
        })
        var listMenuTemp = []
        this.listBarLocaltionByService.forEach(x => {
          listMenuTemp.push({
            BarLocaltionCode: x.Code,
            Code:x.Code,
            ProductName:x.Name,
            text: x.Code + ' - ' + x.Name,
            items: res.Data.Menu.filter(y => y.ParentMenuId == x.Id)
          })
        })
        this.ListService = listMenuTemp
        this.isLoading = false
      }) 
    },
    treeViewSelectionChanged(e) {
      this.syncSelection(e.component)
    },

    treeViewContentReady(e) {
      this.syncSelection(e.component)
    },

    syncSelection(treeView) {
      const selectedService = treeView.getSelectedNodes()
        .map(node => node.itemData)
      this.selectedService = selectedService
    },

    showCheckBoxesModeValueChanged(e) {
      if (e.value === 'selectAll') {
        this.selectionModeValue = 'multiple'
        this.isRecursiveDisabled = false
      }
      this.isSelectionModeDisabled = e.value === 'selectAll'
    },

    selectionModeValueChanged(e) {
      if (e.value === 'single') {
        this.selectNodesRecursiveValue = false
        this.treeView.unselectAll()
      }
      this.isRecursiveDisabled = e.value === 'single'
    },
    treeViewSelectionChangedTransactionCode(e) {
      this.syncSelectionTransactionCode(e.component)
    },

    treeViewContentReadyTransactionCode(e) {
      this.syncSelectionTransactionCode(e.component)
    },

    syncSelectionTransactionCode(treeView) {
      const selectedService = treeView.getSelectedNodes()
        .map(node => node.itemData)
      this.selectedTransactionCode = selectedService
    },
    treeViewSelectionChangedNoService(e) {
      this.syncSelectionNoService(e.component)
    },

    treeViewContentReadyNoService(e) {
      this.syncSelectionNoService(e.component)
    },

    syncSelectionNoService(treeView) {
      const selectedService = treeView.getSelectedNodes()
        .map(node => node.itemData)
      this.ListServiceNoSelect = selectedService
    },

    showCheckBoxesModeValueChangedTransactionCode(e) {
      if (e.value === 'selectAll') {
        this.selectionModeValue = 'multiple'
        this.isRecursiveDisabled = false
      }
      this.isSelectionModeDisabled = e.value === 'selectAll'
    },

    selectionModeValueChangedTransactionCode(e) {
      if (e.value === 'single') {
        this.selectNodesRecursiveValue = false
        this.treeView.unselectAll()
      }
      this.isRecursiveDisabled = e.value === 'single'
    },
    handleClickAddRevenueCenter() {
      this.dataProps = null
      this.$bvModal.show('modal-add-revenue-center')
    },
    AddRevenueCenterChild(data) {
      this.dataProps = data ? data : this.dataRevenueCenter
      this.$bvModal.show('modal-add-revenue-center')
    },
    handleEvent(result) {
      this.getListRevenueCenter()
    },
    async DeleteRevenueCenter(Id) {
      const body = {
        RevenueCenterId: Id
      }
      await reportSetting.api_DRCE01(body).then(res => {
        this.showResToast(res)
        if(res.Status === '200') {
          this.RevenueCenterSelected = null
          this.getListRevenueCenter()
        }
      })
    },
    // khi thêm khu vực kinh doanh vào nhóm doanh thu
    async addZoneRevenueCenter(data) {
      var ZoneIdTemp = []
      if(data === 'PAYMENT') {
        this.BarLocaltionSelectPayment.forEach(x => {
          ZoneIdTemp.push(x.Id)
        })
      } else if (data === 'SERVICE') {
        this.BarLocaltionSelectService.forEach(x => {
          ZoneIdTemp.push(x.Id)
        })
      }
      
      const body = {
        RevenueCenterId: this.RevenueCenterSelected,
        ZoneId: ZoneIdTemp,
        RevenueCenterType: data
      }
      await reportSetting.api_IRCZ01(body).then(res => {
        this.showResToast(res)
        if(res.Status === '200') {
          this.SelectRevenueCenter(this.RevenueCenterSelected)
        }
      })
    },
    // thêm dịch vụ vào nhóm doanh thu
    async updateServiceBarLocaltion() {
      if (JSON.stringify(this.BarLocaltionSelectService) == JSON.stringify(this.listBarLocaltionByService)) {
        var dataUpdateServiceBarLocaltion = []
        this.ListRevenueCenterZoneService.forEach(x => {
          var ListServiceTemp = []
          this.selectedService.forEach(y => {
            if(y.ZoneId) {
              if(y.ZoneId == x.ZoneId) {
                  ListServiceTemp.push({
                    RevenueType: null,
                    Amount: null,
                    ServiceId: y.ProductId
                  })
              }
            }
          })
          dataUpdateServiceBarLocaltion.push({
            Id: x.Id,
            RevenueCenterZoneService:ListServiceTemp
          })
        })
        const body = {
          Operator: this.RevenueCenterZoneOfServiceOperator,
          RevenueCenterZone: dataUpdateServiceBarLocaltion
        }
        await reportSetting.api_URCZ01(body).then(res => {
          this.showResToast(res)
          if(res.Status === '200') {
            this.SelectRevenueCenter(this.RevenueCenterSelected)
            
          }
        })
      } else {
        this.showToast('error', `Vui lòng lưu khu vực kinh doanh trước khi cập nhật dịch vụ`)
      }
    },
    async updatePaymentMethodBarLocaltion() {
      if (JSON.stringify(this.BarLocaltionSelectPayment) == JSON.stringify(this.listBarLocaltionByPaymentMethod)) {
        var dataUpdatePaymentMethod = []
        this.ListRevenueCenterZonePayment.forEach(x => {
          var listPaymentMethod = []
          this.selectedService.forEach(y => {
            if(x.ZoneId == y.ZoneId) {
              listPaymentMethod.push({
                PaymentTypeCode: y.PaymentTypeCode,
                PaymentMethodId: y.Id
              })
            }
          })
          dataUpdatePaymentMethod.push({
            Id: x.Id,
            RevenueCenterZonePaymentMethod:listPaymentMethod
          })
        })
        const body = {
          Operator: this.RevenueCenterZoneOfPaymentOperator,
          RevenueCenterZone: dataUpdatePaymentMethod
        }
        await reportSetting.api_URCP01(body).then(res => {
          this.showResToast(res)
            if(res.Status === '200') {
              this.SelectRevenueCenter(this.RevenueCenterSelected)
            }
        })
      } else {
        this.showToast('error', `Vui lòng lưu khu vực kinh doanh trước khi cập nhật dịch vụ`)
      }
      
    },
    async updateRevenueCenter() {
      if(this.dataRevenueCenter.RevenueCenterChild) {
        var body = {
          RevenueCenter: {
              Id:this.dataRevenueCenter.Id,
              RevenueCenterName: this.dataRevenueCenter.RevenueCenterName,
              RevenueCenterCode: this.dataRevenueCenter.RevenueCenterCode,
              Note: this.dataRevenueCenter.Note,
          }
        }
      } else {
        this.dataRevenueCenter.BookingDetailStatus = this.ListBookingDetailStatus
        var body = {
          RevenueCenter: this.dataRevenueCenter
        }
      }
      await reportSetting.api_URCE01(body).then(res => {
        this.showResToast(res)
        if(res.Status === '200') {
          this.getListRevenueCenter()
          this.SelectRevenueCenter(this.RevenueCenterSelected)
        }
        })
    },
    // async HandleClickDataProcessing() {
    //   this.isLoading = true
    //   var EndDateMax = new Date(this.dataProcessing.StartDate).setMonth(new Date(this.dataProcessing.StartDate).getMonth() + 3)
    //   var StartDateMin = new Date(this.dataProcessing.EndDate).setMonth(new Date(this.dataProcessing.EndDate).getMonth() - 3)
    //   if(new Date(EndDateMax) >= new Date(this.dataProcessing.EndDate) && new Date(StartDateMin) <= new Date(this.dataProcessing.StartDate)) {
    //     const body = {
    //       StartDate: this.dataProcessing.StartDate,
    //       EndDate: this.dataProcessing.EndDate,
    //       RevenueCenterId : this.dataRevenueCenter.RevenueCenterChild.map(x => x.Id)
    //     }
    //     await reportSetting.api_RRCC00(body).then(res => {
    //       this.showResToast(res)
    //       this.isLoading = false
    //     })
    //   } else {
    //     this.showToast('error', `Dữ liệu chỉ được xử lý trong vòng 3 tháng`)
    //     this.isLoading = false
    //   }
    // },
    async HandleClickDataProcessing() {
      const processCode = this.generateUUID() 
      const listRevenueCenterDataProcessing = this.dataRevenueCenter.RevenueCenterChild.map(x => x.Id)
      if(this.dataProcessing.StartDate === this.dataProcessing.EndDate ) {
        this.min = 0
        this.max = 1
        this.check = 0
        this.minTotal = 0
        this.maxTotal = 1
        this.checkTotal = 0
        this.isLoadingDataProcess = true
        const body = {
            StartDate: this.dataProcessing.StartDate,
            EndDate: this.dataProcessing.EndDate,
            RevenueCenterId : listRevenueCenterDataProcessing,
            ProcessDataCode: processCode,
            RStartDate: this.dataProcessing.StartDate,
            REndDate:this.dataProcessing.EndDate
        }

        await reportSetting.api_RRCC00(body).then(res => {
            if(res.Status === '200') {
              // this.HandleClickDataProcessing_RRCC00P(body)
              this.check += 1
            if(this.check === this.max) {
              this.HandleClickDataProcessing_RRCC00D()
                this.showToast('success', this.$t('golf_common_success'))
            }
            } else {
              this.isLoadingDataProcess = false
              this.showToast('error', `Xử lý số liệu lỗi ngày ${ this.convertUTC(this.dataProcessing.StartDate,'DD/MM/YYYY') }`)
            }
        })
    }  else {
        var numberDate = (this.dayjs(this.dataProcessing.EndDate).utc().tz(localStorage.getItem('timeZone')) - this.dayjs(this.dataProcessing.StartDate).utc().tz(localStorage.getItem('timeZone')))/86400000 + 1
        this.min = 0
        this.max = numberDate
        this.check = 0
        this.minTotal = 0
        this.maxTotal = numberDate
        this.checkTotal = 0
        this.isLoadingDataProcess = true
        var checkError = false
        for (let i = 0;i<numberDate; i++) {
            
            if (i == 0) {
                var dateTemp = this.dayjs(this.dataProcessing.StartDate).utc().tz(localStorage.getItem('timeZone'))
                .add(-(localStorage.getItem('timeZoneOffset') / 60), 'hour')
                .format('YYYY-MM-DDTHH:mm:ss')
                var body = {
                    StartDate: dateTemp +'Z',
                    EndDate: dateTemp +'Z',
                    RevenueCenterId : listRevenueCenterDataProcessing,
                    ProcessDataCode: processCode,
                    RStartDate: this.dataProcessing.StartDate,
                    REndDate:this.dataProcessing.EndDate
                }
            } else {
                var dateTemp = this.dayjs(this.dataProcessing.StartDate).utc().tz(localStorage.getItem('timeZone'))
                .add(i,'day')
                .add(-(localStorage.getItem('timeZoneOffset') / 60), 'hour')
                .format('YYYY-MM-DDTHH:mm:ss')
                var body = {
                    StartDate: dateTemp +'Z',
                    EndDate: dateTemp +'Z',
                    RevenueCenterId : listRevenueCenterDataProcessing,
                    ProcessDataCode: processCode,
                    RStartDate: this.dataProcessing.StartDate,
                    REndDate:this.dataProcessing.EndDate
                }
                
            }
            await reportSetting.api_RRCC00(body).then(async res => {
              if(res.Status === '200') {
                  // const checkProcessPayment = await this.HandleClickDataProcessing_RRCC00P(body)
                  // if(checkProcessPayment != '200') {
                  //   checkError = true
                  //   this.showToast('error', `Xử lý số liệu lỗi ngày ${ this.convertUTC(dateTemp,'DD/MM/YYYY') }`)
                  // }
              } else {
                checkError = true
                this.showToast('error', `Xử lý số liệu lỗi ngày ${ this.convertUTC(dateTemp,'DD/MM/YYYY') }`)
              }
              if(checkError) {
                this.isLoadingDataProcess = false
                return
              }
                this.check += 1
                
                if(this.check === this.max) {
                    this.HandleClickDataProcessing_RRCC00D()
                    this.showToast('success', this.$t('golf_common_success'))
                }
            })
            
        }
    } 
    },
    async HandleClickDataProcessing_RRCC00D() {
      const listRevenueCenterDataProcessing = this.dataRevenueCenter.RevenueCenterChild.map(x => x.Id)
      if(this.dataProcessing.StartDate === this.dataProcessing.EndDate ) {
        this.minTotal = 0
        this.maxTotal = 1
        this.checkTotal = 0
        const body = {
            StartDate: this.dataProcessing.StartDate,
            EndDate: this.dataProcessing.EndDate,
            RevenueCenterId : listRevenueCenterDataProcessing,
            RStartDate: this.dataProcessing.StartDate,
            REndDate:this.dataProcessing.EndDate
        }
        await reportSetting.api_RRCC00E({
          Date: this.dataProcessing.StartDate
        })
        await reportSetting.api_RRCC00D(body).then(res => {
            if(res.Status === '200') {
              // this.HandleClickDataProcessing_RRCC00P(body)
              this.checkTotal += 1
            if(this.checkTotal === this.maxTotal) {
                this.isLoadingDataProcess = false
                this.showToast('success', this.$t('golf_common_success'))
            }
            } else {
              this.isLoadingDataProcess = false
              this.showToast('error', `Xử lý số liệu lỗi ngày ${ this.convertUTC(this.dataProcessing.StartDate,'DD/MM/YYYY') }`)
            }
        })
    }  else {
        var numberDate = (this.dayjs(this.dataProcessing.EndDate).utc().tz(localStorage.getItem('timeZone')) - this.dayjs(this.dataProcessing.StartDate).utc().tz(localStorage.getItem('timeZone')))/86400000 + 1
        this.minTotal = 0
        this.maxTotal = numberDate
        this.checkTotal = 0
        var checkError = false
        for (let i = 0;i<numberDate; i++) {
            
            if (i == 0) {
                var dateTemp = this.dayjs(this.dataProcessing.StartDate).utc().tz(localStorage.getItem('timeZone'))
                .add(-(localStorage.getItem('timeZoneOffset') / 60), 'hour')
                .format('YYYY-MM-DDTHH:mm:ss')
                var body = {
                    StartDate: dateTemp +'Z',
                    EndDate: dateTemp +'Z',
                    RevenueCenterId : listRevenueCenterDataProcessing,
                    RStartDate: this.dataProcessing.StartDate,
                    REndDate:this.dataProcessing.EndDate
                }
            } else {
                var dateTemp = this.dayjs(this.dataProcessing.StartDate).utc().tz(localStorage.getItem('timeZone'))
                .add(i,'day')
                .add(-(localStorage.getItem('timeZoneOffset') / 60), 'hour')
                .format('YYYY-MM-DDTHH:mm:ss')
                var body = {
                    StartDate: dateTemp +'Z',
                    EndDate: dateTemp +'Z',
                    RevenueCenterId : listRevenueCenterDataProcessing,
                    RStartDate: this.dataProcessing.StartDate,
                    REndDate:this.dataProcessing.EndDate
                }
                
            }
            await reportSetting.api_RRCC00E({
              Date: dateTemp
            })
            await reportSetting.api_RRCC00D(body).then(async res => {
              if(res.Status === '200') {
                  // const checkProcessPayment = await this.HandleClickDataProcessing_RRCC00P(body)
                  // if(checkProcessPayment != '200') {
                  //   checkError = true
                  //   this.showToast('error', `Xử lý số liệu lỗi ngày ${ this.convertUTC(dateTemp,'DD/MM/YYYY') }`)
                  // }
              } else {
                checkError = true
                this.showToast('error', `Xử lý số liệu lỗi ngày ${ this.convertUTC(dateTemp,'DD/MM/YYYY') }`)
              }
              if(checkError) {
                this.isLoadingDataProcess = false
                return
              }
                this.checkTotal += 1
                
                if(this.checkTotal === this.maxTotal) {
                    this.isLoadingDataProcess = false
                    this.showToast('success', this.$t('golf_common_success'))
                }
            })
            
        }
    } 
    },
    async HandleClickDataProcessing_RRCC00P(body) {
      const res = await reportSetting.api_RRCC00P(body)
      return res.Status
    },
    async updateCourseRevenueCenter() {
      var ListRevenueCenterCourse = []
      this.courses.selected.forEach(x => {
        ListRevenueCenterCourse.push({CourseId: x})
      })
      const body = {
        RevenueCenter: {
          Id: this.RevenueCenterSelected,
          Operator: this.RevenueCenterCourseOperator,
          RevenueCenterCourse: ListRevenueCenterCourse
        }
      }
      await reportSetting.api_URCC01(body).then(res => {
        this.showResToast(res)
          if(res.Status === '200') {
            this.SelectRevenueCenter(this.RevenueCenterSelected)
          }
      })
    },
    async updateGolfClassRevenueCenter() {
      var ListRevenueCenterGolfClass = []
      this.golfClasses.selected.forEach(x => {
        ListRevenueCenterGolfClass.push({GolfClassId: x})
      })
      const body = {
        RevenueCenter: {
          Id: this.RevenueCenterSelected,
          Operator: this.RevenueCenterGolfClassOperator,
          RevenueCenterGolfClass: ListRevenueCenterGolfClass
        }
      }
      await reportSetting.api_URGC01(body).then(res => {
        this.showResToast(res)
          if(res.Status === '200') {
            this.SelectRevenueCenter(this.RevenueCenterSelected)
          }
      })
    },
    async updateTimeRevenueCenter() {
      var checkTimeShift = this.ListTimeShift.filter(x => !x.StartTime || !x.EndTime)
      if(checkTimeShift.length === 0) {
        const body = {
          RevenueCenter: {
            Id: this.RevenueCenterSelected,
            Operator: this.RevenueCenterTimeOperator,
            RevenueCenterTime: this.ListTimeShift
          }
        }
        await reportSetting.api_URCT01(body).then(res => {
          this.showResToast(res)
            if(res.Status === '200') {
              this.SelectRevenueCenter(this.RevenueCenterSelected)
            }
        })
      } else {
        this.showToast('error', `Vui lòng nhập đầy đủ thông tin`)
      }
      
    },
    addTimeShift() {
      this.ListTimeShift.push({
        StartTime: null,
        EndTime: null
      })
    },
    addDiscount() {
      this.ListDiscount.push({
        MinValue: 0,
        MaxValue: 0,
        Operator: "IN"
      })
    },
    deleteTimeShift(index) {
      this.ListTimeShift.splice(index,1)
    },
    deleteDiscount(index) {
      this.ListDiscount.splice(index,1)
    },
    async updateTransactionCode() {
      var data = []
      if(this.ListHotel.selected.length > 0) {
        this.ListHotel.selected.forEach(x => {
          this.selectedTransactionCode.forEach(y => {
            if(y.TransactionCode1) {
              data.push(
                {
                  HotelId: x,
                  TransactionCode: y.TransactionCode1,
                  TransactionSubCode: y.TransactionSubCode
                } 
              )
            }
          })
        })
        const body = {
          RevenueCenter: {
            Id: this.RevenueCenterSelected,
            Operator: this.RevenueCenterHotelTransactionCodeOperator,
            ListRevenueCenterHotelTransactionCode: data
          }
        }
        await reportSetting.api_URCTC1(body)
        .then(res => {
          this.showResToast(res)
          if(res.Status === '200') {
            this.SelectRevenueCenter(this.RevenueCenterSelected)
          }
        })
      } else {
        this.showToast('error', `Vui lòng chọn tối thiểu 1 khách sạn`)
      }
    },
    deleteitemTreeView(data) {
      this.treeView.unselectItem(data.data)
    },
    async updateInfoMemberRevenueCenter() {
      const body = {
        RevenueCenter: {
          Id: this.RevenueCenterSelected,
          OperatorNationality: this.RevenueCenterNationalityCodeOperator,
          OperatorSex: this.RevenueCenterSexCodeOperator,
          SexCode: this.listSex.selected,
          NationalityCode: this.countries.selected
        }
      }
      await reportSetting.api_URCSN1(body)
      .then(res => {
        this.showResToast(res)
          if(res.Status === '200') {
            this.SelectRevenueCenter(this.RevenueCenterSelected)
          }
      })
    },
    changeAllNation(value) {
      if(value) {
        this.countries.selected = this.countries.list.map(x => x.ID)
      } else {
        this.countries.selected = []
      }
    },
    generateUUID() { // Public Domain/MIT
      var d = new Date().getTime();//Timestamp
      var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16;//random number between 0 and 16
          if(d > 0){//Use timestamp until depleted
              r = (d + r)%16 | 0;
              d = Math.floor(d/16);
          } else {//Use microseconds since page-load if supported
              r = (d2 + r)%16 | 0;
              d2 = Math.floor(d2/16);
          }
          return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    },
    checkValueDiscount(type, index,value) {
      if(type == 'min') {
        if(value < 0) {
          this.ListDiscount[index].MinValue = 0 
        }
        if(value > 100) {
          this.ListDiscount[index].MinValue = 100
        }
      }
      if(type == 'max') {
        if(value < 0) {
          this.ListDiscount[index].MaxValue = 0 
        }
        if(value > 100) {
          this.ListDiscount[index].MaxValue = 100
        }
      }
    },
    async updateDiscountRevenueCenter() {
      this.ListDiscount.forEach(x => {
        x.MinValue = Number(x.MinValue)
        x.MaxValue = Number(x.MaxValue)
      })
      const body = {
        RevenueCenter: {
          Id: this.RevenueCenterSelected,
          RevenueCenterDiscount: this.ListDiscount
        }
      }
      await reportSetting.api_URCD01(body).then(res => {
        this.showResToast(res)
          if(res.Status === '200') {
            this.SelectRevenueCenter(this.RevenueCenterSelected)
          }
      })
    },
    async getListRateCode_RRA01_AC2() {
      const body = {
        RateCode: null,
        OpenDate: null,
        ExpirationDate: null,
        CourseId: [],
      }
      await rateSetting
        .getRateList(body)
        .then(res => {
          if (res.Status === '200') {
            const listRateTemp = res.Data.Rate.filter(x => !x.IsDeleted)
            listRateTemp.forEach(x => {
              x.id = x.Id
              x.label = `${x.RateCode} - ${x.RateName}`
            })
            this.listRate = listRateTemp
          } else {
            this.showResToast(res)
          }
        })
    },
    async updateRevenueCenterRate() {
      let RevenueCenterRatetemp = []
      this.listRateSelect.forEach(x => {
        RevenueCenterRatetemp.push(
          {
            RateId: x, 
            Operator: this.RevenueCenterRateOperator
          }
        )
      })
      const body = {
        RevenueCenter: {
          Id: this.RevenueCenterSelected,
          RevenueCenterRate: RevenueCenterRatetemp
        }
      }
      await reportSetting.api_URCR01(body).then(res => {
        this.showResToast(res)
          if(res.Status === '200') {
            this.SelectRevenueCenter(this.RevenueCenterSelected)
          }
      })
    },
    copyRevenueService() {
      this.$bvModal.show('modal-copy-revenue-center')
    },
    async getListServiceNoRevenueCenter() {
      await reportSetting.api_RCS03().then(res => {
          res.Data.Zone.forEach(x => {
              x.label = x.ZoneName,
              x.items = [],
              x.Service.forEach( y => {
                  x.items.push(
                      {
                          label: y.ServiceName,
                          ServiceId: y.ServiceId
                      }
                  )
              })
          })
          this.ListAllServiceNoRevenueCenter = res.Data.Zone
      })
    },
    CountServiceNoSelect() {
      let i = 0
      this.ListAllServiceNoRevenueCenter.forEach(x => {
        i += x.Service.length
      })
      return i
    },
    async getListServiceDuplicate() {
      let list = []
      if(this.listRevenueCenterCheckDuplicate) {
        this.listRevenueCenterCheckDuplicate.forEach(x => {
          if(this.listRevenueCenter.find(y => y.Id == x)) {
            list = [...list, ...this.listRevenueCenter.find(y => y.Id == x).children.map(z => z.Id) ]
          } else {
            list.push(x)
          }
        })
      }
      const body = {
        RevenueCenterId : list,
        RevenueCenterCode : []
      }
      await reportSetting.api_RRCS04(body).then(res => {
        this.listServiceDuplicate = res.Data.Service
      })
    },
  },
}
