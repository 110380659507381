<template>
    <div>
        <b-modal
        id="modal-add-revenue-center"
        v-model="isOpen"
        size="lg"
        centered
        hide-header
        cancel-variant="none"
        ok-variant="primary"
        :no-close-on-backdrop="true"
        >
        <b-card-header class="banned-background-color-primary">
                <div class="d-flex justify-content-between align-items-center block-header">
                <h3  class="mb-0 title-modal color-primary">
                    Thêm nhóm doanh thu
                </h3>
                <feather-icon
                    icon="XIcon"
                    size="24"
                    class="cursor-pointer color-primary"
                    @click="hideModal"
                />
                </div>
            </b-card-header>
        <b-overlay 
            :show="isLoading"
            :opacity="0.3"  
            rounded="sm"
        >
        <b-card-body>
                <b-row>
                      <b-col cols="6">
                        <b-row>
                          <b-form-group class="col-12" label="Mã nhóm doanh thu">
                            <b-form-input v-model="dataAddRevenueCenter.RevenueCenterCode" placeholder="Mã nhóm doanh thu"/>
                          </b-form-group>
                        </b-row>
                        <b-row >
                          <b-form-group class="col-12" label="Tên nhóm doanh thu">
                            <b-form-input v-model="dataAddRevenueCenter.RevenueCenterName" placeholder="Tên nhóm doanh thu" />
                          </b-form-group>
                        </b-row>
                        <b-row>
                          <b-col cols="6">
                            <b-form-group v-if="dataProps" label="Loại nhóm">
                                <v-select
                                    v-model="dataAddRevenueCenter.RevenueCenterType"
                                    label="label"
                                    :options="optionsRevenueCenterType"
                                    :reduce="item => item.value"
                                    :clearable="false"
                                    @input="(dataAddRevenueCenter.TaxsType = null)"
                                />
                            </b-form-group>
                        
                          </b-col>
                          <b-col cols="6" v-if="dataProps && dataAddRevenueCenter.RevenueCenterType === 'REV' || dataAddRevenueCenter.RevenueCenterType === 'REV_HOTEL'">
                            <b-form-group  label="Giá trị">
                                <v-select
                                    v-model="dataAddRevenueCenter.TaxsType"
                                    label="label"
                                    :options="optionsTaxsType"
                                    :reduce="item => item.value"
                                    :clearable="false"
                                />
                            </b-form-group>
                          </b-col>
                          <b-col cols="6" v-if="dataProps && dataAddRevenueCenter.RevenueCenterType === 'ST'">
                            <b-form-group  label="Giá trị">
                                <v-select
                                    v-model="dataAddRevenueCenter.TaxsType"
                                    label="label"
                                    :options="optionsSTType"
                                    :reduce="item => item.value"
                                    :clearable="false"
                                />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-if="dataProps">
                          <b-col>
                            <b-form-group label="Trạng thái booking Golf">
                              <v-select
                                  v-model="dataAddRevenueCenter.BookingDetailStatus"
                                  label="label"
                                  :options="optionBookingStatus"
                                  :reduce="item => item.value"
                                  :clearable="true"
                                  :multiple="true"
                                  placeholder="Trạng thái"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="6">
                        <b-row>
                          <b-form-group class="col-12" label="Mô tả">
                            <b-form-textarea
                                :rows="dataProps ? '10' : '4'"
                                v-model="dataAddRevenueCenter.Note"
                                placeholder="Mô tả"
                            />
                          </b-form-group>
                        </b-row>
                      </b-col>
                    </b-row>
            </b-card-body>
            
        </b-overlay>
            
        <template #modal-footer>
      
      <b-button variant="primary" @click="addRevenueCenter()">
          Lưu
      </b-button>
      
      <b-button  variant="secondary" @click="hideModal()">
          Quay lại
      </b-button>
  </template>
            
        </b-modal>
    </div>
</template>
<script>
import { reportSetting } from '@/api/Report-Setting'
export default {
    props: {
        dataProps: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            dataAddRevenueCenter: {
                RevenueCenterName: null,
                RevenueCenterCode:null,
                Note: null,
                ParentId: null,
                RevenueCenterType: null,
                TaxsType:'NET',
                BookingDetailStatus: null
            },
            isOpen: false,
            ModalParentId: null,
            optionsRevenueCenterType: [
                {
                    label: 'Thanh toán',
                    value: 'PAY'
                },
                {
                    label: 'Doanh thu',
                    value: 'REV'
                },
                {
                    label: 'Thống kê',
                    value: 'ST'
                },
                {
                    label: 'Doanh thu Khách sạn',
                    value: 'REV_HOTEL'
                },
                {
                    label: 'Thanh toán Khách sạn',
                    value: 'PAY_HOTEL'
                }
            ],
            optionsTaxsType: [
                {
                    label:'Không bao gồm thuế phí',
                    value:'NET'
                },
                {
                    label:'Đã bao gồm thuế phí',
                    value:'TOTAL'
                },
                {
                    label:'Thuế VAT',
                    value:'VAT_TAX'
                },
                {
                    label:'Thuế tiêu thụ đặc biệt',
                    value:'S_TAX'
                },
                {
                    label:'Phí dịch vụ',
                    value:'SERVICE_CHARGE'
                }
            ],
            optionsSTType: [
                {
                    label:'Số round',
                    value:'ROUND'
                },
                {
                    label:'Số hố',
                    value:'HOLE'
                },
                {
                    label:'Số golfer',
                    value:'GOLFER'
                },
                {
                    label:'Số lần booking',
                    value:'TIME_BOOKING'
                },
                {
                    label:'Số lần hủy',
                    value:'TIME_CANCEL'
                },
                {
                    label:'Số lần sử dụng dịch vụ',
                    value:'TIME_USING_SERVICE'
                }
            ],
            optionBookingStatus: [
                {
                    value:'BOOKED',
                    label:'Booked'
                },
                {
                    value:'CHECK_IN',
                    label:'Check in'
                },
                {
                    value:'CHECK_OUT',
                    label:'Check out'
                },
                {
                    value:'CANCELLED',
                    label:'Hủy'
                },
                {
                    value:'NO_SHOW',
                    label:'No show'
                }
            ],
            isLoading: false
        }
    },
    watch: {
        isOpen(value) {
            if(value) {
                this.dataAddRevenueCenter.BookingDetailStatus = ['CHECK_OUT']
                if(this.dataProps) {
                    this.dataAddRevenueCenter.ParentId = this.dataProps.Id
                } 
            } else {
                this.dataAddRevenueCenter.RevenueCenterName = null,
                this.dataAddRevenueCenter.RevenueCenterCode = null,
                this.dataAddRevenueCenter.Note = null,
                this.dataAddRevenueCenter.ParentId = null,
                this.dataAddRevenueCenter.RevenueCenterType = null,
                this.dataAddRevenueCenter.TaxsType = null
            }
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-add-revenue-center')
        },
        addRevenueCenter() {
            this.isLoading = true
            if(this.dataAddRevenueCenter.ParentId) {
                if(this.dataAddRevenueCenter.RevenueCenterType != 'PAY' && this.dataAddRevenueCenter.RevenueCenterType != 'PAY_HOTEL') {
                    var body = {
                        RevenueCenter: {
                            RevenueCenterName: this.dataAddRevenueCenter.RevenueCenterName,
                            RevenueCenterCode: this.dataAddRevenueCenter.RevenueCenterCode,
                            Note: this.dataAddRevenueCenter.Note,
                            RevenueCenterType: this.dataAddRevenueCenter.RevenueCenterType,
                            ParentId: this.dataAddRevenueCenter.ParentId,
                            BookingDetailStatus: this.dataAddRevenueCenter.BookingDetailStatus,
                            TaxsType: this.dataAddRevenueCenter.TaxsType
                        }
                    }
                    if(body.RevenueCenter.RevenueCenterName && body.RevenueCenter.RevenueCenterCode && body.RevenueCenter.RevenueCenterType && body.RevenueCenter.TaxsType) {
                        this.addRevenueCenter_API_IRCE01(body)
                    } else {
                        this.isLoading = false
                        this.showToast('error', `Vui lòng nhập đẩy đủ thông tin`)
                    }
                } else {
                    var body = {
                        RevenueCenter: {
                            RevenueCenterName: this.dataAddRevenueCenter.RevenueCenterName,
                            RevenueCenterCode: this.dataAddRevenueCenter.RevenueCenterCode,
                            Note: this.dataAddRevenueCenter.Note,
                            BookingDetailStatus: this.dataAddRevenueCenter.BookingDetailStatus,
                            RevenueCenterType: this.dataAddRevenueCenter.RevenueCenterType,
                            ParentId: this.dataAddRevenueCenter.ParentId,
                        }
                    }
                    if(body.RevenueCenter.RevenueCenterName && body.RevenueCenter.RevenueCenterCode && body.RevenueCenter.RevenueCenterType) {
                        this.addRevenueCenter_API_IRCE01(body)
                    } else {
                        this.isLoading = false
                        this.showToast('error', `Vui lòng nhập đẩy đủ thông tin`)
                    }
                }
            } else {
                var body = {
                    RevenueCenter: {
                        RevenueCenterName: this.dataAddRevenueCenter.RevenueCenterName,
                        RevenueCenterCode: this.dataAddRevenueCenter.RevenueCenterCode,
                        Note: this.dataAddRevenueCenter.Note,
                    }
                }
                if(body.RevenueCenter.RevenueCenterName && body.RevenueCenter.RevenueCenterCode) {
                    this.addRevenueCenter_API_IRCE01(body)
                } else {
                    this.isLoading = false
                    this.showToast('error', `Vui lòng nhập đẩy đủ thông tin`)
                }
                
            }

        },
        async addRevenueCenter_API_IRCE01(body) {
            await reportSetting.api_IRCE01(body).then(res => {
                this.showResToast(res)
                this.isLoading = false
                if(res.Status === '200') {
                    this.$emit('event')
                    this.hideModal()
                }
            })
        }
    },
}
</script>
<style lang="scss">
    
</style>